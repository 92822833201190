import React, { Component, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import api from '../../services/api'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'

import { H3, H4 } from '../common/Headers'
import { Spin } from '../common/Ant'
import SelectField from '../common/formFields/SelectField'


const AssignedFlowMonitors = ({ leakPillarId }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [availableFlowMonitors, setAvailableFlowMonitors] = useState([])
    const [assignedFlowMonitors, setAssignedFlowMonitors] = useState([])
    const [showFlowMonitorForm, setShowFlowMonitorForm] = useState(false);
    const [selectedFlowMonitor, setSelectedFlowMonitor] = useState(null);
    const [selectedFlowMonitorIndex, setSelectedFlowMonitorIndex] = useState(null);

    // ================
    // API Functions
    // ================
    const getFlowMonitors = useCallback(() => {
        setIsLoading(true)

        api
        .getLeakPodAssignedFlowMonitors(leakPillarId)
        .then(data => {
            console.log(data)
            const available = ["---Select a Flow Monitor---"]
            setAvailableFlowMonitors(available.concat(data['available']))
            setAssignedFlowMonitors(data['assigned'])
            setIsLoading(false)
        })
        .catch(e => {
            console.log("Error getting flow monitors")
            console.log(e)
            setIsLoading(false)
        })
    }, [leakPillarId])

    const assignFlowMonitor = useCallback((flow_pillar_id) => {
        setIsLoading(true)

        api
        .assignLeakPodToFlowMonitor(flow_pillar_id, leakPillarId)
        .then(data => {
            setShowFlowMonitorForm(false)
            setSelectedFlowMonitor(null)
            setSelectedFlowMonitorIndex(null)
            getFlowMonitors()
        })
        .catch(e => {
            console.log("Error assigning flow monitor")
            console.log(e)
            setIsLoading(false)
        })
    }, [leakPillarId])

    const unassignFlowMonitor = useCallback((flow_pillar_id) => {
        setIsLoading(true)

        api
        .unassignLeakPodFromFlowMonitor(flow_pillar_id, leakPillarId)
        .then(data => {
            getFlowMonitors()
        })
        .catch(e => {
            console.log(e)
            setIsLoading(false)
        })
    }, [leakPillarId])

    // Effects
    useEffect(() => {
        getFlowMonitors()
    }, [leakPillarId, getFlowMonitors])


    // ================
    // Modal Functions
    // ================
    const handleDropdownChange = (event) => {
        setSelectedFlowMonitorIndex(event.target.value);
        setSelectedFlowMonitor(availableFlowMonitors[event.target.value]);
    };

    const toggleAssignFlowMonitor = () => {
        setShowFlowMonitorForm(!showFlowMonitorForm);
    };
    

    // ================
    // Render
    // ================
    return isLoading ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <div>
        <div className="pa3">
            {/* ================= */}
            {/* Header */}
            {/* ================= */}
            <div className="flex justify-between items-center mb2">
                <H3>Assigned Water Monitors</H3>
                {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                    <button
                    type="button"
                    className="f7 link dim br2 ph3 pv2 dib white bg-dark-green bn"
                    onClick={toggleAssignFlowMonitor}
                >
                        {showFlowMonitorForm ? "Hide Menu" : "Assign Water Monitor"}
                    </button>
                ) : ""}
            </div>

            {/* ================= */}
            {/* Assign a Flow Monitor */}
            {/* ================= */}
            {showFlowMonitorForm && (
                <div className="mb3 ba b--black-10 br3 pa3 bg-white shadow-4">
                    <div className="flex justify-between items-center mb2">
                        <SelectField
                            options={availableFlowMonitors.map(monitor => monitor['pillarId'])}
                            id="flowMonitorSelect"
                            name="flowMonitorSelect"
                            value={selectedFlowMonitorIndex}
                            onChange={handleDropdownChange}
                            label="Select a Water Monitor"
                        />
                        {selectedFlowMonitorIndex && selectedFlowMonitor["pillarId"] && (
                            <button
                                type="button"
                                className="f7 link dim br2 ph3 pv2 dib white bg-dark-blue bn" 
                                onClick={() => assignFlowMonitor(selectedFlowMonitor["pillarId"])}>
                                    Assign Water Monitor
                            </button>
                        )}
                    </div>
                    {selectedFlowMonitorIndex && selectedFlowMonitor["pillarId"] && (
                        <div>
                        <div className="flex items-start">
                            <H4 className="mr2 w-25">{selectedFlowMonitor["pillarId"]}</H4>
                            <H4 className="mr2 w-25">{selectedFlowMonitor["moduleId"]}</H4>
                            <H4 className="mr2 w-25">Activity: {selectedFlowMonitor["activity"]}</H4>
                            <H4 className="mr2 w-25">Status: {selectedFlowMonitor["status"]}</H4>
                        </div>
                        <div className="flex items-start">
                            <H4 className="mr2 w-25">Site: {selectedFlowMonitor["site"]}</H4>
                            <H4 className="mr2 w-25">Building: {selectedFlowMonitor["building"]}</H4>
                            <H4 className="mr2 w-25">Floor: {selectedFlowMonitor["floor"]}</H4>
                            <H4 className="mr2 w-25">Location: {selectedFlowMonitor["locationName"]}</H4>
                        </div>
                        </div>
                    )}
                    
                </div>
            )}

            {/* ================= */}
            {/* Assigned Flow Monitors */}
            {/* ================= */}
            {assignedFlowMonitors.map((monitor, index) => (
                <div
                    key={index}
                    className="mb3 ba b--black-10 br3 pa3 bg-white shadow-4"
                >
                    <div className="flex items-start">
                        <div className="flex items-start mb2 w-90">
                        <H3 className="mr2 w-25">{monitor["pillarId"]}</H3>
                        <H4 className="mr2 w-25">{monitor["moduleId"]}</H4>
                        <H4 className="mr2 w-25">Activity: {monitor["activity"]}</H4>
                        <H4 className="mr2 w-25">Status: {monitor["status"]}</H4>
                        </div>
                        
                    </div>
                    <div className="flex items-start">
                    <div className="flex items-start w-90">
                        <H4 className="mr2 w-25">Site: {monitor["site"]}</H4>
                        <H4 className="mr2 w-25">Building: {monitor["building"]}</H4>
                        <H4 className="mr2 w-25">Floor: {monitor["floor"]}</H4>
                        <H4 className="mr2 w-25">Location: {monitor["locationName"]}</H4>
                    </div>
                    <div className="flex items-center mb2 w-10">
                        {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                            <button
                                type="button"
                                className="f7 link dim br2 ph3 pv2 dib white bg-dark-red bn"
                                onClick={() => unassignFlowMonitor(monitor["pillarId"])}
                            >
                                Unassign
                            </button>
                        ) : ""}
                    </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
    )
}


AssignedFlowMonitors.propTypes = {
  leakPillarId: PropTypes.string.isRequired,
}

export default AssignedFlowMonitors
