import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SelectField extends Component {
    static propTypes = {
      id: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      value: PropTypes.number,
      onChange: PropTypes.func,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      match: PropTypes.object,
      label: PropTypes.string,
    }
  
    render() {
      return (
        <span className="flex flex-column mv1">
          <label className="f7 b db mb1 dark-gray" htmlFor={this.props.id}>
            {this.props.label}
          </label>
          <select
            className="input-reset ba b--black-20 pa1 db w-100 br2 bg-white"
            id={this.props.id}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
            required
          >
            {this.props.options.map((e, index) => (
              <option key={index} value={index}>
                {e}
              </option>
            ))}
          </select>
        </span>
      )
    }
  }

export default SelectField;